@use './../../../../src/SCSS/utils/variables' as *;

//general

.fullSizeModal {
    width: 100%;
    position: absolute;
    height: 100%;
    background: $black10;
    bottom: 0px;
    left: 0;
    z-index: 10;
    backdrop-filter: blur(10px);
    cursor: pointer;
    padding: 20px 20px 0px;
    
    .fullSizeModal-container {
        cursor: default;
        position: relative;
        height: 100%;
        width: 100%;
        background: $white;
        padding: 15px;
        border-radius: 20px 20px 0px 0px;
        .close {
            background-color: $gray2F;
            border-radius: 50%;
            position: absolute;
            width: 35px;
            height: 35px;
            color: $white;
            font-size: 8px;
            top: 0;
            right: 0;
            transform: translate(25%, -25%);
            z-index: 1;
        }
        .title {
            position: absolute;
            transform: translate(0, -50%);
            background: $white;
            top: 15px;
            left: 30px;
            padding: 0 15px;
            z-index: 1;
        }
    }
    .content {
        height: 100%;
        padding: 15px;
        overflow-x: hidden;
        overflow-y: auto;
        .body {
            min-height: 150px;
        }
    }
}

//mediaqueries en orden descendente
@media ($media_lg) {
    .fullSizeModal {
        padding: 10px;
        .fullSizeModal-container {
            padding: 5px !important;
            border-radius: 10px;
            .close {
                height: 25px;
                width: 25px;
                font-size: 5px !important;
                svg {
                    font-size: 12px;
                }
            }
            .title {
                span,
                svg {
                    font-size: 12px !important;
                }
            }
            .content {
                padding: 10px !important;
            }
            span,
            div {
                font-size: 14px;
            }
        }
    }
}