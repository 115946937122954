@use './../../../../../SCSS/utils/variables' as *;

//general

.modal-body{
    #attendances-tabs{
        position: sticky;
        left: 0;
    }
    .historic-tab {
        table{
            &.specialTable{
                thead th,
                tr td {
                    button {
                        border: none;
                        opacity: 1;
                    }
                }
                .sessionExtraStudentCount{
                    .studentCount{
                        button{
                            background-color: transparent;
                            svg {
                                font-size: 14px;
                            }
                            span {
                                font-size: 16px;
                                margin-left: 5px;
                            }
                            &:not(.noExtraStuden){
                                color: $link;
                            }
                            &.noExtraStuden{
                                cursor: default;
                            }
                        }
                    }
                }
                .sessionExtraStudentCount,
                .sessionDocuments{
                    td{
                        border: none !important;
                        &:first-child{
                            color: $purple;
                        }
                        &:first-child,
                        &:last-child{
                            width: auto;
                            border: none !important;
                        }
                    }
                }
            }
        }
    }
}
.extraStudentTable{
    tbody td {
        font-size: 14px;
        margin: 0;
        p{
            margin: 0;
        }
    }
}


//mediaqueries en orden descendente

@media ($media_lg) {
    .modal-body{
        #attendances-tabs{
           font-size: 12px;
           .nav-tabs .nav-link{
            padding: 4px 8px;
        }
        }
        .historic-tab {
            table{
                &.specialTable{
                    td{
                        &.sticky{
                            width: auto;
                            min-width: 130px;
                            max-width: 130px;
                            position: sticky !important;
                            left: 0;
                        }
                    }
                    th{
                        &.sticky{
                            width: auto;
                            position: sticky !important;
                            left: 0;
                            background-color: $white;
                        }
                    }
                }
            }
        }
    }
}