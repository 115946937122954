.wa-btn {
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.wa-btn:hover {
    background-color: darkblue;
}

/* Specific rule to disable hover when the button has the "link" class */
.wa-btn.link:hover {
    background-color: initial; /* or any other value to reset the background */
}

.link {
    cursor: "pointer";
    text-decoration: underline;
    background-color: transparent;
}

.rounded {
    border-radius: 15px;
}

.primary {
    background-color: blue;
    color: white;
}

.wa-btn-sm {
    padding: 5px 10px;
    font-size: 12px;
}

.wa-btn-lg {
    padding: 15px 30px;
    font-size: 18px;
}