@use './../../../src/SCSS/utils/variables' as *;

//general
.chatNoteContainer {
    border-radius: 5px;
    padding: 15px;
    background-color: $black10;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    .chatNoteTile{
        &:not(:first-child) {
            margin-top: 15px;
        }
        .chatNote{
            &:before {
                border: 6px solid $white;
                content: '';
                border-left-color: transparent;
                border-bottom-color: transparent;
                border-right-color: transparent;
                position: absolute;
                bottom: -5px;
                left: -4px;
                transform: rotate(-45deg);
                border-radius: 1px;
            }
            border-radius: 5px 5px 5px 0px;
            padding: 10px 10px 14px 10px;
            position: relative;
            background: $white;
            width: fit-content;
            max-width: 200px;
            display: block;
            &.chatNoteSelected{
                background: $blueEdit;
                &::before {
                    border: 6px solid $blueEdit;
                    border-left-color: transparent;
                    border-bottom-color: transparent;
                    border-right-color: transparent;
                }
            }
            .text{
                word-break: break-word;
            }
            .chatNoteUpdated{
                position: relative;
                font-size: 9px;
                font-weight: bold;
                bottom: 0;
                right: -6px;
                color: $lightGray;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: end;
                height: 0;
                .by, .at {
                    white-space: nowrap;
                }
                .by{
                    margin-right: 3px;
                    margin-left: 5px;
                }
            }
        }
        .edit{
            width: 25px;
            height: 25px;
            background-color: $white;
            border-radius: 5px;
            margin-left: 15px;
        }
    }
}
.editNote{
    .editNoteLabel{
        font-size: 12px;
    }
    .editNoteContent{
        width: 100%;
        display: flex;
        justify-content: space-between;
        .edit{
            width: calc(100% - 10px);
            border: 1px solid $gray;
            margin-right: 10px;
            border-radius: 5px;
            padding: 3px 10px;
        }
        .send{
            background-color: $greenSuccess;
            color: $white;
            padding: 5px;
            font-size: 12px;
            min-width: 33px;
            height: 33px;
            border-radius: 5px;
        }
    }
}

//mediaqueries en orden descendente
@media ($media_lg) {
    .chatNoteContainer {
        margin-bottom: 12px;
        .chatNoteTile{
            .chatNote{
                .text{
                    font-size: 12px;
                }
                .chatNoteUpdated{
                    font-size: 8px;
                }
            }
            .edit{
                width: 20px;
                height: 20px;
                margin-left: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 12px;
            }
        }
    }
    .editNote{
        .editNoteLabel{
            font-size: 10px;
        }
        .editNoteContent{
            width: 100%;
            display: flex;
            justify-content: space-between;
            .edit{
                height: 25px;
            }
            .send{
                font-size: 10px;
                min-width: 25px;
                height: 25px;
            }
        }
    }
    .selectToEdit{
        font-size: 12px;
    }
}