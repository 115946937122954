@use './../../../../../src/SCSS/utils/variables' as *;

//general
.attendancesContainer {
    padding : 30px;
    .pageHeader {
        font-size: 20px;
        margin-bottom: 0;
    }
    .noResult {
        padding : 25px;
    }
    .containerTable {
        padding: 10px 10px 0px 10px;
    }
    .attendancesPagination{
        display: flex;
        justify-content: center;
        position: sticky;
        bottom: 0;
        background: $white;
    }
}

//mediaqueries en orden descendente
@media ($media_lg) {    
    .attendancesContainer{
        padding: 20px;
        .pageHeader {
            font-size: 16px;
        }
    }
}
