.discount-button {
    font-size: 10px !important;
    line-height: 5px !important;
    width: 35% !important;
    text-align: left !important;
    padding: unset !important;
    position: relative !important;
    border-radius: 0px 10px 0px 0px !important;
    background: #8F91DA !important;
    color: #FFFFFF !important;
}

.discount-button:hover {
    background: #5154AE !important;
}

.discount-button:active {
    background: #9F99F0 !important;
}

.discount-button:disabled {
    background: #707070 !important;
}