@use './../../../../src/SCSS/utils/variables' as *;

//general
.miniModal {
    position: absolute;
    top: 0;
    left: 0;
    backdrop-filter: blur(10px);
    background-color: $black40;
    height: 100vh;
    width: 100%;
    z-index: 10;
    padding: 20px 15px;
    .miniModalContainer {
        flex-direction: column;
        display: flex;
        background-color: $white;
        border-radius: 15px;
        padding: 20px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        max-height: 100%;
        max-width: 100%;
        .miniModalHeader {
            display: flex;
            align-items: center;
            margin-bottom: 12px;
            .title {
                color: $gray45;
                width: 100%;
                margin-bottom: 0;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: center;
            }
            .close {
                width: 0;
                .closeBtn {
                    background-color: $gray2F;
                    border-radius: 50%;
                    min-width: 30px;
                    height: 30px;
                    color: $white;
                    font-size: 6px;
                    transform: translate(-50%, -50%);
                    &:disabled {
                        background-color: $gray;
                        cursor: default;
                    }
                }
            }
        }
        .miniModalBody {
            margin-top: 0px;
            overflow: auto;
        }
    }
}

//mediaqueries en orden descendente
@media ($media_lg) {
    .miniModal {
        padding: 15px 10px;
        .miniModalContainer {
            padding: 15px;
            .miniModalHeader {
                margin-bottom: 5px;
                .title {
                    font-size: 16px;
                }
                .close {
                    width: 0;
                    .closeBtn {
                        min-width: 25px;
                        height: 25px;
                        font-size: 5px;
                        transform: translate(-65%, -35%);
                    }
                }
            }
        }
    }
}