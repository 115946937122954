@media screen
{
	.financePrint
	{
		display : none;
	}
}

@media (min-width : 1014px)
{
	.searchPanel
	{
		width : 72%;
	}
}

@media (min-width : 300px) and (max-width : 1015px)
{
	.searchPanel
	{
		width : 90%;
	}

	.searchPanel>.row >.col-lg-9
	{
		padding-right : 50px;
	}
}

@media (min-width : 300px)
{
	.h-sm-none
	{
		display : none !important;
	}
}

@media (max-width : 300px)
{
	.d-xs-none
	{
		display : none !important;
	}

	.d-xs-inline-block
	{
		display : inline-block !important;
	}
}

@media (max-width : 320px)
{
	.themeMenu.active
	{
		width : 100% !important;
	}
}

@media (max-width : 800px)
{
	.compactableSection.sidePrompt
	{
		display : none;
	}

	.sidePrompt
	{
		width : 100% !important;
	}
}

@media(max-width : 173px)
{
	#sideMenu tr td:nth-child(2)
	{
		padding-left : unset !important;
	}
}

@media screen and (max-width:601px)
{
	table.cardFormat
	{
		word-wrap:break-word;
	}

	table.cardFormat thead
	{
		display : none;
	}

	table.cardFormat td
	{
		display            : block;
		float              : left;/* ie9 and under hack */
		width              : 100%;
		clear              : both;
		background         : #F5F5F5;
		padding            : 15px;
		-moz-box-sizing    : border-box;
		-webkit-box-sizing : border-box;
		box-sizing         : border-box;
	}
	table.cardFormat tbody, table.cardFormat tr
	{
		display       : block;
		border-radius : 15px;
	}

	table.cardFormat td:before
	{
		content     : attr(data-th);
		display     : block;
		font-weight : bold;
		margin      : 0 15px 2px;
		color       : #000;
	}

	table.cardFormat tbody tr
	{
		float         : left;
		width         : 100%;
		margin-bottom : 10px;
	}
}

.usageEmphasis
{
	-webkit-animation : warn 3s infinite;
	animation         : warn 3s infinite;
	background        :          #880F0F;
	text-align        :           center;
	position          :           sticky;
	float             :             left;
	left              :              0px;
	height            :             100%;
	width             :             100%;
}

@-webkit-keyframes warn
{
	0%   {opacity :   0%;}
	50%  {opacity : 100%;}
	100% {opacity :   0%;}
}

@keyframes warn
{
	0%   {opacity :   0%;}
	50%  {opacity : 100%;}
	100% {opacity :   0%;}
}

html
{
	overflow : hidden;
}

.topNav
{
	padding-top     :    10px;
	height          :    60px;
	display         :    flex;
	justify-content :   right;
	align-items     : stretch;
	background      : #8F91DA;
}

.topNavSearch
{
	position         : relative;
	height           : 35px;
	background-color : #FFFFFF;
	display          : flex;
	align-items      : center;
	box-shadow       : var(--box-shadow);
	border-radius    : 25px;
	overflow         : hidden;
}

.topNavSearch > input
{
	height           : 100%;
	width            : 100%;
	padding          : 10px 60px 10px 20px;
	font-size        : 1rem;
	border-radius    : var(--border-radius);
	color            : #000000;
	background-color : #FFFFFF;
}

.topNavSearch > i
{
	font-size : 1.5rem;
	position  : absolute;
	left      : 20px;
}

.topNavRight
{
	display     : flex;
	align-items : center;
}

.topNavRightItem ~ .topNavRightItem
{
	margin-left  : 30px;
	margin-right : 30px;
}

.notificationItem
{
	display     : flex;
	align-items : center;
	padding     : 5px;
}

.notificationItem:hover
{
	background-color : var(--second-bg);
}

.notificationItem > i
{
	margin-right : 20px;
	font-size    : 1.5rem;
}

.topnavRightUser
{
	display     : flex;
	align-items : center;
}

.topNavRightUserImage
{
	width         : 40px;
	height        : 40px;
	border-radius : 50%;
	overflow      : hidden;
	margin-right  : 10px;
	background    : #FFFFFF;
}

.topNavRightUserImage > img
{
	width : 100%;
}

.topNavRightUserName
{
	font-size   : 1rem;
	font-weight : 600;
}

.themeMenu
{
	z-index          : 100 !important;
	position         : fixed;
	left             : -300px;
	top              : 0;
	width            : 300px;
	max-width        : 100%;
	height           : 100vh;
	z-index          : 99;
	background-color : #141535;
	border-right     : 7px solid #5154AE;
	box-shadow       : var(--box-shadow);
	transition       : left 0.5s var(--transition-cubic);
}

.themeMenu.active
{
	left: 0;
}

.themeMenuClose
{
	background-color : transparent;
	font-size       : 3rem;
}

.themeMenuSelect
{
	margin-top : 40px;
}

.modeList
{
	margin-top : 20px;
}

.modeList > li
{
	display	 : flex;
	align-items : center;
	cursor	  : pointer;
}

.modeList > li ~ li
{
	margin-top : 10px;
}

.modeListColor
{
	width           : 30px;
	height          : 30px;
	border-radius   : 50%;
	display	        : flex;
	align-items     : center;
	justify-content : center;
	margin-right    : 10px;
	font-size       : 1.5rem;
}

.modeList_color > i
{
	transform  : scale(0);
	transition : transform 0.5s var(--transition-cubic);
}

.modeList_color.active > i
{
	transform : scale(1);
}

.sidebar
{
	min-width        : var(--sidebar-width);
	height           : 100vh;
	position         : fixed;
	left             : 0;
	top              : 0;
	background-color : var(--main-bg);
	box-shadow       : var(--box-shadow);
}

.sidebarLogo
{
	height          : 150px;
	display         : flex;
	align-items     : center;
	justify-content : center;
}

.sidebarLogo > img
{
	height : 45px;
}

.sidebarItem
{
	padding : 0 20px;
}

.sidebarItemInner
{
	padding     : 15px 25px;
	display     : flex;
	align-items : center;
	font-weight : 600;
	transition  : color .3s ease 0s;
}

.sidebarItemInner > i
{
	margin-right : 10px;
	font-size    : 1.5rem;
}

.sidebarItemInner > span
{
	text-transform : capitalize;
}

.sidebarItemInner:hover
{
	color : var(--main-color);
}

.sidebarItemInner.active
{
	border-radius    : var(--border-radius);
	color            : var(--txt-white);
	background-image : linear-gradient(to right, var(--main-color), var(--second-color));
}

.btnPurple
{
	border     : 2px solid #8F91DA !important;
	background : #141535 !important;
	color      : #FFFFFF !important;
}

.btnPurple:hover
{
	background : #2F316B !important;
}

.btnPurple:active
{
	background : #5154AE !important;
}

.btnPurple:disabled
{
	background : #8F91DA !important;
}

.btnOutlinePurple
{
	border     : 2px solid #8F91DA !important;
	color      : #8F91DA !important;
}

.btnOutlinePurple:hover
{
	background : #2F316B !important;
}

.btnOutlinePurple:active
{
	background : #5154AE !important;
}

.btnOutlinePurple:disabled
{
	background : #8F91DA !important;
}

.btnVeryPeri
{
	background : #8F91DA !important;
	color      : #FFFFFF !important;
}

.btnVeryPeri:hover
{
	background : #5154AE !important;
}

.btnVeryPeri:active
{
	background : #9F99F0 !important;
}

.btnVeryPeri:disabled
{
	background : #707070 !important;
}
.react-tiny-popover-container
{
	z-index : 100;
	border  : 1px solid #C7C7C7;
}

.btn-unstyled
{
	padding : 12px 6px;
}

.conflicting
{
	background : #FA6666;
}

.reserved
{
	background : #AAB0BB;
}

.chosen
{
	background : #8F91DA;
}

.triangle, .triangle:before, .triangle:after
{
	width  : 30px;
	height : 30px;
}

.triangle
{
	overflow       : hidden;
	position       : relative;
	margin         : 7em auto 0;
	border-radius  : 20%;
	transform      : translateY(50%) rotate(120deg) skewY(30deg) scaleX(.866);
	cursor         : pointer;
	pointer-events : none;
}

.triangle:before, .triangle:after
{
	position       : absolute;
	background     : orange;
	pointer-events : auto;
	content        : '';
}

.triangle:before
{
	border-radius : 20% 20% 20% 53%;
	transform     : scaleX(1.155) skewY(-30deg) rotate(-30deg) translateY(-42.3%) skewX(30deg) scaleY(.866) translateX(-24%);
}

.triangle:after
{
	border-radius : 20% 20% 53% 20%;
	transform     : scaleX(1.155) skewY(-30deg) rotate(-30deg) translateY(-42.3%) skewX(-30deg) scaleY(.866) translateX(24%);
}

.notesField
{
	min-height    : 130px;
	border-radius : 3px;
	border-color  : #8F91D;
	width         : 100%;
	resize        : none;
}

.detailPicture
{
	width         : 100%;
	border        : 3px solid #8F91DA;
	border-radius : 100%;
	margin        : auto;
	max-width     : 50px;
	max-height    : 50px;
	min-height    : 50px;
	min-width     : 50px;
}

.relatedPicture
{
	width         :  100%;
	border-radius :  100%;
	margin        :  auto;
	max-width     : 250px;
	max-height    : 250px;
	min-height    : 125px;
	min-width     : 125px;
	border        : 3px solid #8F91DA;
}

.requirementAlert
{
	display       : inline-block;
	background    : #FFA100;
	color         : #FFFFFF;
	width         :    20px;
	height        :    20px;
	text-align    :  center;
	border-radius :     5px;
}

.multiselect-container.multiSelectContainer input
{
	width : 100%;
}

.multiselect-container.multiSelectContainer input:focus
{
	border : none;
}

.multiselect-container.multiSelectContainer .search-wrapper.searchWrapper
{
	display   : flex;
	flex-flow : wrap;
}

.multiselect-container.multiSelectContainer .chip
{
	overflow      :   auto;
	text-overflow : ellipsis;
	position      : relative;
	padding-left  : 24px;
	overflow      : hidden;
	display       : block;
}

.multiselect-container.multiSelectContainer img
{
	float    : left;
	position : absolute;
	left     : 2px;
	top      : 7px;
}

.nestedSelection .chip
{
	display : none !important;
}

.mixed
{
	background :
		-webkit-linear-gradient(45deg, #AAB0BB 25%, transparent 25%, transparent 75%, #AAB0BB 75%, #AAB0BB 0),
		-webkit-linear-gradient(45deg, #AAB0BB 25%, transparent 25%, transparent 75%, #AAB0BB 75%, #AAB0BB 0), #FFC0CB;
	background :
		   -moz-linear-gradient(45deg, #AAB0BB 25%, transparent 25%, transparent 75%, #AAB0BB 75%, #AAB0BB 0),
		   -moz-linear-gradient(45deg, #AAB0BB 25%, transparent 25%, transparent 75%, #AAB0BB 75%, #AAB0BB 0), #FFC0CB;
	background : 
		        linear-gradient(45deg, #AAB0BB 25%, transparent 25%, transparent 75%, #AAB0BB 75%, #AAB0BB 0),
		        linear-gradient(45deg, #AAB0BB 25%, transparent 25%, transparent 75%, #AAB0BB 75%, #AAB0BB 0), #FFC0CB;
	background-repeat         : repeat, repeat;
	background-position       : 0px 0, 5px 5px;
	-webkit-transform-origin  : 0 0 0;
	        transform-origin  : 0 0 0;
	-webkit-background-origin : padding-box, padding-box;
	        background-origin : padding-box, padding-box;
	-webkit-background-clip   : border-box, border-box;
	        background-clip   : border-box, border-box;
	-webkit-background-size   : 10px 10px, 10px 10px;
	        background-size   : 10px 10px, 10px 10px;
	-webkit-box-shadow        : none;
	        box-shadow        : none;
	text-shadow               : none;
	-webkit-transition        : none;
	   -moz-transition        : none;
	     -o-transition        : none;
	        transition        : none;
	-webkit-transform         : scaleX(1) scaleY(1) scaleZ(1);
	        transform         : scaleX(1) scaleY(1) scaleZ(1);
}

.fluentBox
{
	display         : flex;
	flex-wrap       : wrap;
	justify-content : center;
	align-items     : stretch;
}

.fluentBlock
{
	flex-grow : 1;
	width     : 250px;
	min-width : 100px;
	max-width : 100%;
	height    : 100%;
	padding   : 10px 5px;
}

.fluentBlockLg
{
	flex-grow : 1;
	width     : 375px;
	min-width : 100px;
	max-width : 100%;
	height    : 100%;
	padding   : 10px 5px;
}

.fluentBlockSm
{
	flex-grow : 1;
	width     : 125px;
	min-width : 100px;
	max-width : 100%;
	height    : 100%;
	padding   : 10px 5px;
}

.fluentBlockXs
{
	flex-grow : 1;
	width     : 100px;
	min-width : 100px;
	max-width : 100%;
	height    : 100%;
	padding   : 10px 5px;
}

.pictureAttach
{
	border     : 1px solid var(--main-bg) !important;
	color      : var(--second-bg)      !important;
	position   : relative;
	background :  #C7C7C7                 !important;
	height     :     56px;
	bottom     :     40px;
	left       :      16%;
}

.text-dark
{
	color : #000000 !important;
}

.verticalStretching
{
	display   : flex;
	flex-flow : column;
}

.verticalStretching .react-datepicker-wrapper
{
	flex : 1 1 auto;
}

.verticalStretching .react-datepicker__input-container
{
	height : 100%;
}

.react-datepicker__input-container input
{
	width : 100%;
}

.react-select__placeholder
{
	white-space   : nowrap;
	overflow      : hidden;
	text-overflow : ellipsis;
}

.unselectable
{
	-webkit-touch-callout : none;
	-webkit-user-select   : none;
	-khtml-user-select    : none;
	-moz-user-select      : none;
	-ms-user-select       : none;
	user-select           : none;
}
.filename
{
	display     : inline-block;
	max-width   : 50%;
	overflow    : hidden;
	white-space : pre;
}

.ellipsis
{
	white-space   : nowrap;
	overflow      : hidden;
	text-overflow : ellipsis;
}

.reverse-ellipsis
{
	text-overflow : clip;
	position      : relative;
}

.reverse-ellipsis::before
{
	position     : absolute;
	z-index      : 1;
	left         : -1em;
	padding-left : 1em;
	margin-left  : 0.5em;
}

.reverse-ellipsis span
{
	min-width     : 100%;
	position      : relative;
	display       : inline-block;
	float         : right;
	overflow      : visible;
}

.reverse-ellipsis span::before
{
	position : absolute;
	display  : inline-block;
	width    : 1em;
	height   : 1em;
	z-index  : 200;
	left     : -.5em;
}

.react-datepicker-wrapper
{
	width : 100%;
}

.card
{
	border-radius : 5px !important;
}

.loader
{
	width      : 100%;
	height     : 5px;
	position   : relative;
	background : #C7C7C7;
	overflow   : hidden;
	bottom     : 2px;
}
.loader::after
{
	content    : "";
	width      : 192px;
	height     : 5px;
	background : #000000;
	position   : absolute;
	top        : 0px;
	left       : 0px;
	box-sizing : border-box;
	animation  : animloader 2s linear infinite;
}

@keyframes animloader
{
	0%
	{
		left      : 0;
		transform : translateX(-100%);
	}
	100%
	{
		left      : 100%;
		transform : translateX(0%);
	}
}

.react-datepicker__tab-loop
{
	position : fixed;
	z-index  : 100;
}

.underlineHeadingContainer
{
	font-size     : 14px;
	font-weight   : bold;
	margin-bottom : 20px;
	color : #5154AE;
}

.underlineHeadingContainer hr
{
	background-image : -webkit-linear-gradient(to right, #FA0000FF 0%, #FA0000CC 50%, #FA000000 100%);
	background-image : -moz-linear-gradient(to right   , #FA0000FF 0%, #FA0000CC 50%, #FA000000 100%);
	background-image : -o-linear-gradient(to right     , #FA0000FF 0%, #FA0000CC 50%, #FA000000 100%);
	background-image : linear-gradient(to right        , #FA0000FF 0%, #FA0000CC 50%, #FA000000 100%);
	margin           : 0px;
	height           : 3px;
	width            : 65%;
	border-top       : unset;
	opacity          : unset;
	margin           : unset;
}

.withOptions
{
	font-size     : 10px !important;
	line-height   : 5px !important;
	width         : 35% !important;
	text-align    : left !important;
	padding       : unset !important;
	position      : relative !important;
	border-radius : 0px 10px 0px 0px !important;
}

.withOptions:after
{
	content      :   "";
	width        :  0px;
	height       :  0px;
	top          : 14px;
	left         : 18px;
	border-style : solid;
	position     : absolute;
	border-width : 10px 10px 0px 0px;
	border-color : #FFFFFF00 #FFFFFFFF #FFFFFF00 #FFFFFF00;
	pointer-events : none;
}

.operatorOptions:after
{
	content :   "";
	width :  0px;
	height :  0px;
	top : 20px;
	right : 15px;
	border-style : solid;
	position : relative;
	border-width : 10px 10px 0px 0px;
	border-color : #FFFFFF00 #FFFFFFFF #FFFFFF00 #FFFFFF00;
	pointer-events : none;
}

.loadingBar
{
	z-index       : 3;
	width         : 100%;
	position      : absolute;
	background    : #8F91DA;
	border-radius : 0px 0px 15px 15px;
	background    : linear-gradient(0deg, #8F91DA22 0%, #8F91DA 25%);
}

.loader0
{
	width            : 100%;
	height           : 12px;
	font-size        : 30px;
	background-size  : 1em 1em;
	background-color : #FFFFFF;
	box-sizing       : border-box;
	display          : inline-block;
	animation        : barStripe 1s linear infinite;
	background-image : linear-gradient(135deg, #C7C7C7 25%, #FFFFFF 25%, #FFFFFF 50%, #C7C7C7 50%, #C7C7C7 75%, #FFFFFF 75%, #FFFFFF);
}

@keyframes barStripe
{
	0%   {background-position : 0   0;}
	100% {background-position : 0 2em;}
}

.salary:hover
{
	text-decoration : underline;
	cursor          : pointer;
}

.linkInvoice
{
	text-decoration : underline;
	cursor          : pointer;
}

.hex
{
	float            : left;
	background-color : var(--main-bg);
	border-width     : 1px 0px;
	border-style     : solid;
	border-color     : #D2D2D2;
}

.hexL
{
	float         : left;
	border-right  : 30px solid #D2D2D2;
	border-top    : 19px solid transparent;
	border-bottom : 21px solid transparent;
	height        : 42px;
	width         : 62px;
	position      : relative;
}

.hexR
{
	float         : left;
	border-left   : 30px solid #D2D2D2;
	border-top    : 19px solid transparent;
	border-bottom : 21px solid transparent;
	height        : 42px;
	width         : 62px;
	position      : relative;
}

.hexL0
{
	float         : left;
	border-right  : 31px solid var(--main-bg);
	border-top    : 20px solid transparent;
	border-bottom : 22px solid transparent;
	position      : relative;
	left          : 33px;
	bottom        : 19px;
}

.hexR0
{
	float         : left;
	border-left   : 31px solid var(--main-bg);
	border-top    : 20px solid transparent;
	border-bottom : 22px solid transparent;
	position      : relative;
	right         : 32px;
	bottom        : 19px;
}

.rect-select__menu
{
	zIndex : 999999999999;
}

.multiselect-container li.option:not(.multiselect-container li.option.highlight)
{
	color : #000000;
}

div.search-wrapper.searchWrapper
{
	background : var(--main-bg);
}

.selectContainer
{
	margin-bottom  : 15px;
	display        : flex;
	border-bottom  : 2px solid #8F91DA;
	width          : 100%;
	height         : 47px;
	position       : relative;
}

.selectContainer label
{
	/*position: absolute;*/
	font-size : 12px;
	color     : var(--second-txt-color);
	min-width : max-content;
}

.selectContainer .iconLeft
{
	height       : 20px;
	width        : 20px;
	bottom       : -20px;
	margin-right : 10px;
	position     : relative;
}

.selectContainer .iconRight
{
	height   : 20px;
	width    : 20px;
	bottom   : 2px;
	position : absolute;
	right    : 6px;
	filter   : invert();
}

.selectContainer select
{
	position    : relative;
	background  : none;
	border      : none;
	margin-top  : 20px;
	cursor      : pointer;
	/*z-index     : 10;*/
	appearance  : none;
	width       : 100%;
	font-weight : 500;
	color       : var(--main-txt-color);
	outline     : none;
}

.reactSelectContainer
{
	/* bottom: 0px;
	position: absolute !important; */
	height: 100% !important;
}

.reactSelectControl
{
	background    : none !important;
	border-width  : 0px 0px 2px 0px !important;
	bottom        : 0px !important;
	position      : absolute !important;
	border-radius : 0px !important;
	border-color  : #8f91da !important;
	width         : 100% !important;
	box-shadow    : none !important;
}

.reactSelectValueContainer
{
	padding: 0px !important;
}

.reactSelectPlaceholder
{
	margin-left : 0px !important;
	position    : absolute !important;
	bottom      : 0px !important;
	left        : 0px !important;
	font-weight : 500 !important;
}

.reactSelectInputContainer
{
	margin : 0px !important;
}

.reactSelectIndicatorSeparator
{
	display: none !important;
}

.reactSelectMenu
{
	display    : block !important;
	visibility : visible !important;
	/* background-color: aqua !important; */
}

.reactSelectMenuList
{
	/* background-color: aquamarine !important; */
}

.reactSelectOption
{
	/* background-color: !important; */
}

.sideSlot
{
	display        : inline-block;
	vertical-align : bottom;
	width          : 50%;
	height         : 25px;
}

.sideSlot.left
{
	border-radius : 5px 0px 0px 5px;
}

.sideSlot.right
{
	border-radius : 0px 5px 5px 0px;
}

.end
{
	border-right : 1px solid #000000;
}

.matchingSchedule > .tooltip-inner
{
	min-width : 300px;
}

ul.display-none.nav.nav-tabs
{
	display : none !important
}

.weekDayClassroom
{
	padding : 0px 0px 10%;
	height : 100%;
	display : flex;
	flex-direction : column;
	align-items : flex-start;
}

.weekDayClassroom > :last-child div
{
	border-radius : 0px 0px 15px 15px !important;
}