.loginContent
{
	position            : absolute;
	height              : 100%;
	width               : 100%;
	background-image    : url(/src/assets/images/loginBg.jpg);
	background-repeat   : no-repeat;
	background-size     : cover;
	background-position : center;
	display             : flex;
	align-items         : center;
	justify-content     : center;
}

.loginForm
{
	height           : 540px;
	width            : 80%;
	background-color : white;
	position         : relative;
	display          : flex;
	border-radius    : 3px;
	box-shadow       : 0px 4px 8px #3c080791;
}

@media(min-width : 840px)
{
	.loginForm
	{
		width : 840px !important;
	}

	.loginFormRight
	{
		visibility : visible !important;
		width      : 50% !important;
	}

	.loginFormLeft
	{
		width : 50% !important;
	}
}

@media(min-width : 450px)
{
	.loginForm
	{
		width : 420px;
	}

	.loginFormLeft .logo
	{
		width : 300px !important;
	}

	.loginFormLeft .form
	{
		width : 350px !important;
	}
}

.loginFormLeft
{
	width          : 100%;
	height         : 100%;
	position       : relative;
	display        : flex;
	flex-direction : column;
	align-items    : center;
}

.loginFormRight
{
	visibility        : hidden;
	width             : 0%;
	height            : 100%;
	position          : relative;
	background-image  : url(/src/assets/images/loginFormBg.png);
	background-repeat : no-repeat;
	background-size   : cover;
	border-radius     : 0px 3px 3px 0px;
}

.loginFormLeft .logo
{
	/* background-color: aqua; */
	width             : 70%;
	height            : 50px;
	margin            : 45px 50px 0px 50px;
	background-image  : url(/src/assets/images/loginLogo.png);
	background-repeat : no-repeat;
	background-size   :contain;
}

.loginFormLeft .form
{
	/* background-color:aquamarine; */
	width          : 95%;
	height         : 400px;
	text-align     : left;
	letter-spacing : 0px;
	display        : flex;
	flex-direction : column;
}

.loginFormLeft .form #emailForgot
{
	margin-bottom : 20px;
}

.login h1
{
	color         : black !important;
	font-size     : 34px  !important;
	font-weight   : 500   !important;
	margin-bottom : 10px  !important;
}

.login h2
{
	color         : black !important;
	font-size     : 26px  !important;
	font-weight   : 500   !important;
	margin-bottom : 10px  !important;
}

.loginFormLeft .form p
{
	color          : black !important;
	letter-spacing : 0px;
	font-weight    : 400;
	font-family    : Roboto;
	font-size      : 14px;
	max-width      : 290px;
	margin-bottom  : 26px;
}

.loginFormLeft .form input
{
	width        : 100%;
	border-style : solid;
	border-width : 1px 1px 1px 0px;
	border-color : #00000020;
	height       : 50px;
	padding-left : 15px;
}

.forgotOrRemember
{
	display         : flex;
	flex-wrap       : wrap;
	justify-content : space-between;
	align-items     : center;
	position        : relative;
	color           : #939393;
	font-size       : 12px;
}

.logInLink
{
	display         : flex;
	flex-wrap       : wrap;
	justify-content : center;
	align-items     : center;
	position        : relative;
	color           : #939393;
	font-size       : 12px;
}

.logInLink a
{
	cursor: pointer;
}

.formCheck
{
	display         : flex;
	width           : 50%;
	justify-content : flex-start;
	align-items     : center;
	margin-bottom   : 10px;
}

.forgotPassword
{
	min-width       : 110px;
	width           : 50%;
	display         : flex;
	justify-content : flex-end;
	align-items     : center;
	margin-bottom   : 10px;
}

.forgotPassword a
{
	cursor : pointer;
}

.formCheck #checkbox
{
	width        : 15px;
	height       : 15px;
	margin-right : 6px;
	cursor       : pointer;
}

.formCheck label
{
	position : relative !important;
}

.divInput
{
	display  : flex;
	position : relative;
	border   : 1px solid #0000002D;
}

.divInput label
{
	/*position   : absolute;*/
	left       : 15px;
	font-size  : 12px;
}

.selectInput
{
	background-color : #8F91DA;
}

.focusInputBar
{
	width      : 10px;
	height     : 50px;
	background : #8F91DA;
	border     : 1px 0px 1px 1px solid #000000;
}

.buttonLoginSubmit
{
	width         : 125px;
	height        : 40px;
	border        : 0px;
	background    : #2F316B;
	color         : #FFFFFF;
	border-radius : 30px;
}

.buttonRegisterSubmit
{
	width         : 125px;
	height        : 40px;
	border        : 0px;
	background    : #8F91DA;
	color         : #FFFFFF;
	border-radius : 30px;
}

.buttonLoginSignup
{
	width            : 125px;
	height           : 40px;
	border           : 0px;
	color            : #5154AE;
	background-color : #ffffff;
	border-color     : #5154AE;
	border-width     : 1px;
	border-style     : solid;
	border-radius    : 30px;
}

.loginButtons
{
	display         : flex;
	align-items     : center;
	justify-content : space-around;
	margin-top      : 25px;
}

.forgotButton
{
	display         : flex;
	align-items     : center;
	justify-content : space-around;
	margin-bottom   : 25px;
}

.login input:focus::placeholder
{
	color : transparent;
}

.hidden
{
	visibility : hidden;
}

.visible
{
	visibility: visible;
}

/*.displayNone
{
	display : none !important;
}*/

.invalidPass
{
	width            : 100%;
	height           : 50px;
	background-color : #d52424;
	margin-bottom    : 15px;
	color            : #ffffff;
	display          : flex;
	align-items      : center;
	justify-content  : center;
	font-size        : 12px;
	border-radius    : 3px;
}

.btnModal
{
	background-color : #2F316B !important;
	border-color     : #2F316B !important;
}
