.dropdown
{
	/*position : relative;*/
	z-index  : 100;
}

.dropdownToggle
{
	border           : 0;
	outline          : 0;
	background-color : transparent;
	position         : relative;
}

.dropdownToggle > i
{
	font-size : 2.5rem;
	color     : var(--txt-color);
}

.dropdownToggleBadge
{
	display          : flex;
	align-items      : center;
	justify-content  : center;
	position         : absolute;
	top              : -2px;
	right            : -3px;
	height           : 22px;
	width            : 22px;
	border-radius    : 50%;
	background-color : #FA0000;
	color            : var(--txt-white);
	font-size        : 0.55rem;
	padding-right    : 1px;
	padding-top      : 1px;
	border           : 4px solid #8F91DA;
}

.dropdownContent
{
	position         : absolute;
	top              : calc(100% + 5px);
	right            : 0;
	width            : max-content;
	max-width        : 400px;
	background-color : var(--main-bg);
	box-shadow       : var(--box-shadow);
	border-radius    : var(--border-radius);
	overflow         : hidden;
	transform-origin : top right;
	transform        : scale(0);
	transition       : transform 0.3s ease 0s;
	z-index          : 1
}

.dropdownContent.active
{
	transform  : scale(1);
	transition : transform 0.5s var(--transition-cubic);
}

.dropdownFooter
{
	padding    : 20px;
	text-align : center;
}
