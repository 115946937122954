@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

:root
{
	--main-bg      : #FFFFFF;
	--second-bg    : #FAFAFB;
	--txt-color    : #455560;
	--txt-white    : #FFFFFF;
	--main-color   : #349EFF;
	--second-color : #62B4FF;
	--box-shadow   : rgba(149, 157, 165, 0.2) 0px 8px 24px;

	--main-bg-light          : #FAFAFB;
	--second-bg-light        : #FFFFFF;
	--main-txt-color-light   : #000000;
	--second-txt-color-light : #AAB0BB;
	--txt-color-light        : #455560;
	--box-shadow-light       : rgba(149, 157, 165, 0.2) 0px 8px 24px;

	--main-bg-dark          : #000000;
	--second-bg-dark        : #2D2D2D;
	--txt-color-dark        : #BBBBBB;
	--main-txt-color-dark   : #FFFFFF;
	--second-txt-color-dark : #DEDEDE;
	--box-shadow-dark       : rgba(0, 0, 0, 0.2) 0px 5px 10px;

	--main-color-blue   : #349EFF;
	--second-color-blue : #62B4FF;

	--main-color-red   : #FB0B12;
	--second-color-red : #FF4A6B;

	--main-color-cyan   : #10D4D2;
	--second-color-cyan : #2AE9E6;

	--main-color-green   : #019707;
	--second-color-green : #4CAF50;

	--main-color-orange   : #d68102;
	--second-color-orange : #FCA11A;

	--sidebar-width    : 300px;
	--border-radius    :  15px;
	--topnav-height    :  60px;
	--transition-cubic : cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.optionListContainer
{
	overflow-y : auto !important;
}

*
{
	padding                     : 0;
	margin                      : 0;
	box-sizing                  : border-box;
	-webkit-tap-highlight-color : transparent;
}

body
{
	font-family             : "Roboto", sans-serif;
	-webkit-font-smoothing  : antialiased;
	-moz-osx-font-smoothing : grayscale;
	letter-spacing          : 0.7px;
	height                  : 100%;
}

a
{
	text-decoration : none;
	color           : unset;
}

a:hover
{
	color : var(--main-color);
}

button
{
	cursor  : pointer;
	border  : 0;
	outline : 0;
	color   : var(--txt-color);
}

ul
{
	list-style-type : none;
}

input
{
	border  : 2px solid transparent;
	outline : 0;
}

input:focus
{
	border : 2px solid var(--main-color);
}

label
{
	display       : inline-block;
	/*padding-left  : 20px;*/
	font-size     : 12px;
	position      : absolute;
	color         : var(--second-txt-color);
	/*top           : 0;*/
	width         : auto;
	white-space   : nowrap;
	overflow      : hidden;
	text-overflow : ellipsis;
	/*margin-bottom : 29px;*/
}

.page-header
{
	margin-bottom  : 40px;
	text-transform : capitalize;
}

.card
{
	padding          : 30px;
	margin-bottom    : 30px;
	background-color : var(--second-bg);
	box-shadow       : var(--box-shadow);
	border-radius    : var(--border-radius);
}

.full-height
{
	height : 100%;
}

.card.full-height
{
	height : calc(100% - 30px);
}

.card_header
{
	text-transform : capitalize;
}

/*.card > div ~ div
{
	margin-top : 30px;
}*/

.card_footer
{
	text-align     : center;
	text-transform : capitalize;
}

.light-background
{
	background-color : var(--main-bg-light);
	color            : #000000;
}

.dark-background
{
	background-color : var(--main-bg-dark);
	color            : #FFFFFF;
}

.blue-color
{
	background-color : var(--main-color-blue);
	color            : #FFFFFF;
}

.red-color
{
	background-color : var(--main-color-red);
	color            : #FFFFFF;
}

.cyan-color
{
	background-color : var(--main-color-cyan);
	color            : #FFFFFF;
}

.green-color
{
	background-color : var(--main-color-green);
	color            : #FFFFFF;
}

.orange-color
{
	background-color : var(--main-color-orange);
	color            : #FFFFFF;
}

div.ReactModal__Content.ReactModal__Content--after-open
{
	width  : 75%;
	margin : 5% auto 5% auto;
}

.form-control
{
	background : var(--main-bg-color);
	color      : var(--main-txt-color)
}

.x
{
	padding-left : 15px;
}

.fileUploader
{
	width : unset;
}

.fileContainer
{
	background      : unset;
	box-shadow      : unset;
	position        : unset;
	border-radius   : unset;
	padding         : unset;
	display         : unset;
	align-items     : unset;
	justify-content : unset;
	flex-direction  : unset;
	margin          : unset;
	transition      : unset;
}

.fileContainer .chooseFileButton
{
	width   : 50px;
	height  : 50px;
	padding : unset;
	margin  : unset;
}

.roView
{
	white-space   : nowrap;
	overflow      : hidden;
	text-overflow : ellipsis;
}

div.ReactModal__Overlay.ReactModal__Overlay--after-open
{
	background              : unset !important;
	-webkit-backdrop-filter : blur(5px);
	backdrop-filter         : blur(5px);
}

.callout
{
	padding           : 20px;
	margin            : 20px 0;
	border            : 1px solid #eee;
	border-left-width : 5px;
	border-radius     : 3px;
}

.callout h4
{
	margin-top    : 0;
	margin-bottom : 5px;
}
.callout p:last-child
{
	margin-bottom : 0;
}

.callout code
{
	border-radius : 3px;
}

.callout+.callout
{
	margin-top : -5px;
}
.callout-default
{
	border-left-color : #777777;
}

.callout-default h4
{
	color : #777777;
}

.callout-primary
{
	border-left-color : #428bca;
}

.callout-primary h4
{
	color : #428BCA;
}
.callout-success
{
	border-left-color : #5CB85C;
}

.callout-success h4
{
	color : #5CB85C;
}

.callout-danger
{
	border-left-color : #D9534F;
	background        : #FF0000;
	color             : #FFFFFF;
}

.callout-danger h4
{
	color: #D9534F;
}

.callout-warning
{
	border-left-color : #F0AD4E;
	background        : #FFC10780;
}

.callout-warning h4
{
	color : #F0AD4E;
}

.callout-info
{
	border-left-color: #5BC0DE;
}

.callout-info h4
{
	color : #5BC0DE;
}

textarea
{
	background : var(--main-bg-color);
	color      : var(--main-txt-color);
}

fieldset
{
	border        : 1px solid #000000;
	border-radius : 15px;
	padding       : 15px;
}

legend
{
	float         : unset;
	width         : unset;
	padding       : unset;
	margin-bottom : unset;
	font-size     : unset;
	font-size     : unset;
	line-height   : unset;
}

@media(max-width : 435px)
{
	.firstColumn
	{
		display : none;
	}
}

@media(min-width:436px)
{
	.descriptiveRow
	{
		display : none;
	}
}

.to
{
	width   : 24px;
	height  : 24px;
	padding : 0px;
	color   : #000000 !important;
}

.to.check
{
	border-radius : 50%;
	background    : #FFFFFF;
}

.prompt
{
	white-space   : nowrap;
	overflow      : hidden;
	text-overflow : ellipsis;
	padding       : 5px 0px;
	color         : #FFFFFF;
	font-weight   : 900;
}

.statusCard, .successButton
{
	padding          : 30px;
	display          : flex;
	align-items      : center;
	flex-flow        : wrap;
	background-color : var(--main-bg);
	box-shadow       : var(--box-shadow);
	border-radius    : var(--border-radius);
	position         : relative;
	overflow         : hidden;
	z-index          : 1;
	transition       : color 0.5s ease 0s;
	margin-bottom    : 30px;
}

.successButton
{
	border-radius : 5px;
	margin        : unset;
}

.statusCardIcon
{
	width           : 30%;
	height          : 100%;
	font-size       : 3rem;
	display         : flex;
	align-items     : center;
	justify-content : center;
	flex-grow       : 1;
	z-index         : 1;
}

.statusCardInfo
{
	flex-grow      : 1;
	text-align     : center;
	z-index        : 1;
	text-transform : capitalize;
}

.statusCardInfo > h4
{
	font-size     : 2.5rem;
	margin-bottom : 10px;
}

.statusCard::before, .successButton::before
{
	content          : "";
	width            : 100%;
	padding-top      : 100%;
	border-radius    : 50%;
	background-image : linear-gradient(to top right, var(--main-color), var(--second-color));
	position         : absolute;
	left             : -50%;
	top              : 0;
	transform        : scale(0);
	transition       : transform 0.8s ease 0s;
}

.statusCard:hover::before, .successButton::before
{
	transform : scale(3);
}

.statusCard:hover, .successButton
{
	color : var(--txt-white);
}

.statusCard.active::before, .ssuccessButon::before
{
	transform : scale(3);
}

.statusCard.active, .successButton
{
	color : var(--txt-white);
}