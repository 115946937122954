@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

:root {
	--second-bg: #FAFAFB;
	--txt-color: #455560;
	--txt-white: #FFFFFF;
	--main-color: #349EFF;
}

.table-wrapper {
	width: 100%;
	overflow-x: auto;
	/* Allow horizontal scrolling if needed */
}

table.tableComponent {
	font-family: "Roboto", sans-serif;
}

table.tableComponent .tableWrapper {
	overflow-y: auto;
}

table.tableComponent {
	width: 100%;
	min-width: 400px;
	border-spacing: 10px;
	border-collapse: unset;
	overflow-x: scroll;
}

table.tableComponent thead,
table.specialTable thead {
	background-color: var(--second-bg)
}

table.tableComponent tr {
	text-align: left;
}

table .tableComponent th,
.tableComponent td {
	text-transform: initial;
	padding: 0px 5px 5px 5px;
}

.tableComponent .tableComponent th,
.tableComponent .tableComponent td {
	padding: 0px;
}

.tableComponent .tableComponent {
	border-spacing: 0px;
}

table.tableComponent th {
	border-bottom: 1px solid var(--txt-color)
}

table.tableComponent td {
	border-bottom: 1px solid #C7C7C7
}

table.tableComponent tr td,
.tableComponent td,
.tableComponent thead th,
.tableComponent tr td {
	border-bottom: 1px solid #C7C7C7
}

.tableComponent>tbody>tr:hover {
	background-color: var(--main-color) !important;
	color: var(--txt-white) !important;
}

.tableComponent>tbody>tr:hover svg{
	color: var(--txt-white) !important;
}

.tableComponent>tbody>tr:hover svg.disabled{
	color: #C7C7C7 !important;
}

.tableComponent>tbody>tr:hover>td {
	outline: 10px solid var(--main-color);
}

.not-hover>tbody>tr:hover {
	background-color: initial !important;
	color: initial !important;
}

.not-hover>tbody>tr:hover>td {
	outline: 0px !important;
}

.filter-datepicker {
	border-bottom: 2px solid #8f91da;
}

@media (max-width: 768px) {

    /* Estilos para dispositivos hasta 768px */
    
    table.tableComponent thead tr th div,
    table.tableComponent tbody tr td{
        font-size: 12px !important;
    }
}