.attendance-modal button:disabled.close {
    background-color: #C7C7C7 !important;
    cursor: default;
}

table.attendanceTable {
    border-spacing: 5px 0px;
}

table.attendanceTable button {
    font-size: 20px;
}

table.attendanceTable td {
    vertical-align: middle;
    padding: 10px 5px;
    border-top: 1px solid #00000000;
}

table.attendanceTable tr td:first-child:not(.border-bottom-0),
table.attendanceTable tr td:last-child:not(.border-bottom-0) {
    border-top: 1px solid #00000000;
    border-bottom: 1px solid #C7C7C7 !important;
}

tr.noActive,
tr.noActive td {
    background-color: #e4e4e4;
}


tr.expiredBalance,
tr.expiredBalance td {
    background-color: #f8d7da !important;
}

tr.noKits,
tr.noKits td {
    /* ffd0b1 */
    background-color: #fed9bf;
}

tr.noActive button {
    border: none;
}

.onlyAdmin {
    font-size: 14px !important;
    margin: 0 !important;
}

.cellMobileStyles {
    font-size: 14px;
    margin: 0;
}

table.attendanceTable tr.noActive td:not(:last-child) {
    box-shadow: 10px 0px 0 #e4e4e4;
    color: #a9a9a9;
}

table.attendanceTable tr.expiredBalance td:not(:last-child) {
    box-shadow: 10px 0px 0 #f8d7da !important;
}

table.attendanceTable tr.noKits td:not(:last-child) {
    box-shadow: 10px 0px 0 #fed9bf;
}

table.attendanceTable thead tr th:not(:last-child),
table.attendanceTable thead tr td:not(:last-child) {
    box-shadow: 10px 0px 0 #fff;
}

table.attendanceTable thead tr th:first-child,
table.attendanceTable thead tr th:last-child {
    border-bottom: 1px solid #000000 !important;
}

table.attendanceTable thead {
    position: sticky;
    z-index: 1;
    top: -1px;
    background-color: white;
}

table.attendanceTable thead {
    padding: 5px;
}

table.attendanceTable tr td:first-child {
    border-radius: 5px 0 0 5px;
}

table.attendanceTable tr td:last-child {
    border-radius: 0 5px 5px 0;
}


/* Extra pequeño (móviles) */
@media (max-width: 576px) {

    /* Estilos para dispositivos de hasta 576px */
    .modal_header_title_v {
        font-size: 12px !important;
    }

    .modal_header_info_card_span_v {
        font-size: 10px !important;
    }

    table.attendanceTable td {
        font-size: 10px !important;
    }

    table.attendanceTable tr {
        font-size: 10px !important;
    }
    
    .onlyAdmin {
        display: none;
    }

    .cellMobileStyles {
        font-size: 12px !important;
    }

    .headerMobileStyles {
        font-size: 14px !important;
    }
    .styles_modal_select_control_v {
        font-size: 10px !important;
        min-width: 150px !important;
        min-height: 25px !important;
        max-height: 25px !important;
    }
    .styles_modal_select_dropdownIndicator_v {
        padding: 0 4px !important;
    }
    .styles_modal_select_option_v {
        font-size: 10px !important;
    }
    .styles_modal_select_input_v {
        font-size: 10px !important;
    }
    .styles_modal_select_single_v {
        font-size: 10px !important;
    }
       
    .attendance-modal-back .attendance-modal .select-component{
        margin-bottom: 0 !important;
        margin-top: 5px !important;
    }
    .attendance-modal-back .attendance-modal .footer-content{
        padding-top: 5px !important;
    }
    .attendance-modal-back .attendance-modal .footer-content .end-buttons{
        flex-wrap: wrap;
    }
    .attendance-modal-back .attendance-modal .attendance-modal-header{
        margin-bottom: 10px !important;
    }
    .attendance-modal-back .attendance-modal .fotter-btn-mobile,
    .attendance-modal-back .attendance-modal .footer-content button{
        height: 25px !important;
        width: 25px !important;
        padding: 0 5px !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .attendance-modal-back .attendance-modal .fotter-btn-icon-mobile,
    .attendance-modal-back .attendance-modal .footer-content button svg{
        font-size: 12px !important;
    }
    .menotrStylesMobile .menotrStylesMobileItem {
        display: none;
    }
    table.attendanceTable button {
        font-size: 14px;
    }
    .textMobile{
        font-size: 10px !important
    }
}

/* Pequeño (teléfonos grandes o pequeños tablets) */
@media (min-width: 577px) and (max-width: 768px) {

    /* Estilos para dispositivos entre 577px y 768px */
    .modal_header_title_v {
        font-size: 12px !important;
    }

    .modal_header_info_card_span_v {
        font-size: 10px !important;
    }

    table.attendanceTable td {
        font-size: 10px !important;
    }

    table.attendanceTable tr {
        font-size: 12px !important;
    }
    .onlyAdmin {
        display: none;
    }

    .cellMobileStyles {
        font-size: 12px !important;
    }

    .headerMobileStyles {
        font-size: 14px !important;
    }
    .styles_modal_select_control_v {
        font-size: 10px !important;
        min-width: 150px !important;
        min-height: 25px !important;
        max-height: 25px !important;
    }
    .styles_modal_select_dropdownIndicator_v {
        padding: 0 4px !important;
    }
    .styles_modal_select_option_v {
        font-size: 10px !important;
    }
    .styles_modal_select_input_v {
        font-size: 10px !important;
    }
    .styles_modal_select_single_v {
        font-size: 10px !important;
    }
       
    .attendance-modal-back .attendance-modal .select-component{
        margin-bottom: 0 !important;
        margin-top: 5px !important;
    }
    .attendance-modal-back .attendance-modal .footer-content{
        padding-top: 5px !important;
    }
    .attendance-modal-back .attendance-modal .attendance-modal-header{
        margin-bottom: 10px !important;
    }
    .attendance-modal-back .attendance-modal .fotter-btn-mobile,
    .attendance-modal-back .attendance-modal .footer-content button{
        height: 25px !important;
        width: 25px !important;
        padding: 0 5px !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .attendance-modal-back .attendance-modal .fotter-btn-icon-mobile,
    .attendance-modal-back .attendance-modal .footer-content button svg{
        font-size: 12px !important;
    }
    .menotrStylesMobile .menotrStylesMobileItem {
        display: none;
    }
    table.attendanceTable button {
        font-size: 14px;
    }
    .textMobile{
        font-size: 10px !important
    }
}

/* Mediano (tabletas y pequeños desktops) */
@media (min-width: 769px) and (max-width: 992px) {
    /* Estilos para dispositivos entre 769px y 992px */
    .modal_header_title_v {
        font-size: 12px !important;
    }

    .modal_header_info_card_span_v {
        font-size: 10px !important;
    }

    table.attendanceTable td {
        font-size: 10px !important;
    }

    table.attendanceTable tr {
        font-size: 12px !important;
    }
    .onlyAdmin {
        display: none;
    }

    .cellMobileStyles {
        font-size: 12px !important;
    }

    .headerMobileStyles {
        font-size: 14px !important;
    }
    .styles_modal_select_control_v {
        font-size: 10px !important;
        min-width: 150px !important;
        min-height: 25px !important;
        max-height: 25px !important;
    }
    .styles_modal_select_dropdownIndicator_v {
        padding: 0 4px !important;
    }
    .styles_modal_select_option_v {
        font-size: 10px !important;
    }
    .styles_modal_select_input_v {
        font-size: 10px !important;
    }
    .styles_modal_select_single_v {
        font-size: 10px !important;
    }
       
    .attendance-modal-back .attendance-modal .select-component{
        margin-bottom: 0 !important;
        margin-top: 5px !important;
    }
    .attendance-modal-back .attendance-modal .footer-content{
        padding-top: 5px !important;
    }
    .attendance-modal-back .attendance-modal .attendance-modal-header{
        margin-bottom: 10px !important;
    }
    .attendance-modal-back .attendance-modal .fotter-btn-mobile,
    .attendance-modal-back .attendance-modal .footer-content button{
        height: 25px !important;
        width: 25px !important;
        padding: 0 5px !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .attendance-modal-back .attendance-modal .fotter-btn-icon-mobile,
    .attendance-modal-back .attendance-modal .footer-content button svg{
        font-size: 12px !important;
    }
    .menotrStylesMobile .menotrStylesMobileItem {
        display: none;
    }
    table.attendanceTable button {
        font-size: 14px;
    }
    .textMobile{
        font-size: 10px !important
    }
}

/* Grande (desktops medianos o grandes) */
@media (min-width: 993px) and (max-width: 1200px) {
    /* Estilos para dispositivos entre 993px y 1200px */
}

/* Extra grande (pantallas grandes como monitores 4K) */
@media (min-width: 1201px) {
    /* Estilos para dispositivos mayores a 1201px */
}