
@media (max-width: 768px) {

    /* Estilos para dispositivos hasta 768px */
    
    .styles_container {
        padding: 10px 0  !important;
    }
    .button_pagination{
        padding: 0px !important;
        height: 25px;
        width: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .button_pagination span{
        font-size: 12px !important;
    }
    .select_pagination{
        padding: 5px !important;
    }
}