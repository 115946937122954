.theme-mode-light
{
	--main-bg          : var(--main-bg-light);
	--second-bg        : var(--second-bg-light);
	--txt-color        : var(--txt-color-light);
	--main-txt-color   : var(--main-txt-color-light);
	--second-txt-color : var(--second-txt-color-light);
	--box-shadow       : var(--box-shadow-light);
}

.theme-mode-dark
{
	--main-bg          : var(--second-bg-dark);
	--second-bg        : var(--main-bg-dark);
	--txt-color        : var(--txt-color-dark);
	--main-txt-color   : var(--main-txt-color-dark);
	--second-txt-color : var(--second-txt-color-dark);
	--box-shadow       : var(--box-shadow-dark);
}

.theme-color-blue
{
	--main-color   : var(--main-color-blue);
	--second-color : var(--second-color-blue);
}

.theme-color-red
{
	--main-color   : var(--main-color-red);
	--second-color : var(--second-color-red);
}

.theme-color-cyan
{
	--main-color   : var(--main-color-cyan);
	--second-color : var(--second-color-cyan);
}

.theme-color-green
{
	--main-color   : var(--main-color-green);
	--second-color : var(--second-color-green);
}

.theme-color-orange
{
	--main-color   : var(--main-color-orange);
	--second-color : var(--second-color-orange);
}
