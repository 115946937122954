@use './utils/variables' as *;

.black{
    color: $black !important;
}
.white{
    color: $white !important;
}
.gray{
    color: $gray !important;
}
.lightGray{
    color: $lightGray !important;
}
.purple{
    color: $purple !important;
}
.link{
    color: $link !important;
}
.redDay{
    color: $redDay !important;
}
.blueDay{
    color: $blueDay !important;
}
.blueEdit{
    color: $blueEdit !important;
}
.greenSuccess{
    color: $greenSuccess !important;
}
.greenActive{
    color: $greenActive !important;
}
.flexCenter{
    display: flex;
    justify-content: center;
    align-items: center;
}