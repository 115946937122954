table.commonTable .tableWrapper, table.specialTable .tableWrapper
{
	overflow-y : auto;
}

table.commonTable, table.specialTable, table.uncommonTable, .standardTable
{
	width           : 100%;
	min-width       : 400px;
	border-spacing  : 10px;
	border-collapse : unset;
}

table.commonTable thead, table.specialTable thead
{
	background-color : var(--second-bg);
}

table.commonTable tr, table.specialTable tr, table.uncommonTable tr, table.standatdTable tr
{
	text-align : left;
}

table .commonTable th, .commonTable td, table .specialTable th, .specialTable td, table .uncommonTable th, table .standardTable th
{
	text-transform : initial;
	padding        : 0px 5px 5px 5px;
}

.commonTable .commonTable th, .commonTable .commonTable td, .specialTable .specialTable th, .specialTable .specialTable td, 
.uncommonTable .commonTable th
{
	padding : 0px;
}

.commonTable .commonTable, .specialTable .specialTable
{
	border-spacing : 0px;
}

table.commonTable th, table.specialTable th, table.uncommonTable th, table.standardTable th
{
	border-bottom  : 1px solid var(--txt-color);
}

table.commonTable td, table.specialTable td, table.uncommonTable td, table.standardTable td
{
	border-bottom  : 1px solid #C7C7C7;
	vertical-align : top;
}

table.commonTable thead th:first-child, .commonTable thead th:last-child, .commonTable tr td:first-child,
table.commonTable tr td:last-child, .commonTable td:last-child,
table.specialTable thead th:first-child, .specialTable thead th:last-child, .specialTable tr td:first-child,
table.specialTable tr td:last-child, .specialTable td:last-child,
table.uncommonTable thead th:first-child, .uncommonTable thead th:last-child, .uncommonTable tr td:first-child,
table.uncommonTable tr td:last-child, .uncommonTable td:last-child,
table.standardTable thead th:first-child, .standardTable thead th:last-child, .standardTable tr td:first-child,
table.standardTable tr td:last-child, .standardTable td:last-child
{
	border-bottom : unset !important;
	width         : 10px;
}

.commonTable>tbody>tr:hover, .uncommonTable tr
{
	background-color : var(--main-color) !important;
	color            : var(--txt-white)  !important;
}


.commonTable>tbody.course>tr:hover
{
	color            : unset !important;
}

.commonTable>tbody>tr:hover>td, .uncommonTable td
{
	outline : 10px solid var(--main-color);
}

.tableDanger td
{
	outline : 10px solid #DC3545;
	color   : #FFFFFF;
}

table.commonTable .tablePagination, table.specialTable .tablePagination
{
	display         : flex;
	width           : 100%;
	justify-content : flex-end;
	align-items     : center;
	margin-top      : 20px;
}

table.commonTable .tablePaginationItem ~ .tablePaginationItem, table.specialTable .tablePaginationItem ~ .tablePaginationItem
{
	margin-left : 10px;
}

table.commonTable .tablePaginationItem, table.specialTable .tablePaginationItem
{
	width           : 30px;
	height          : 30px;
	border-radius   : 50%;
	display         : flex;
	align-items     : center;
	justify-content : center;
	cursor          : pointer;
}

table.commonTable .tablePaginationItem.active, .tablePaginationItem.active:hover,
table.specialTable .tablePaginationItem.active
{
	background-color : var(--main-color);
	color            : var(--txt-white);
	font-weight      : 600;
}

table.commonTable .tablePaginationItem:hover
{
	color            : var(--txt-white);
	background-color : var(--second-color);
}

.specialTable td
{
	color : #000000;
}

.specialTable .specialTable td
{
	color : var(--main-txt-color);
}

.schedule
{
	background : var(--main-bg-color);
	color      : var(--main-txt-color);
}

.disabledTable
{
	background : #DADADA;
}

.disabledTable tr, .disabledTable td
{
	outline : 10px solid #DADADA;
}
