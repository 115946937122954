:root
{
	--gray : #8F91DA;
}

.showInvoiceDetail:hover
{
	cursor: pointer;
}

.overflowContainer
{
	overflow-y: scroll;
	overflow-x: hidden;
}

.overflowContainer::-webkit-scrollbar
{
	width: 5px;
	/* Remove scrollbar space */
	background: transparent;
	/* Optional: just make scrollbar invisible */
}

.overflowContainer::-webkit-scrollbar-thumb
{
	background: var(--gray);
}

.transition-width {
    transition: width 1s ease;
}